import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Angular2PrestaQuery, Angular2PrestaService } from 'angular2-presta';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute } from '@angular/router';
declare let jQuery: any
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {


  product: any;
  key: any;
  p: any

  item: any = {
    id: null,
    quantity: 1,
    size: 'S',
    color: null,
    code: null
  }


  // Inject Angular2PrestaService
  constructor(private _service: Angular2PrestaService, public rest: RestService, private route: ActivatedRoute) {
    this.key = rest.key
    this.route.queryParams.subscribe(params => {
        this.p = params['id'];
        this.item.id = this.p
        
    });
  }

  ngOnInit() {
    let queryProducts: Angular2PrestaQuery = {
      // define resource products, categories ...
      // check full list http://doc.prestashop.com/display/PS16/Web+service+reference
      resource: "products",
      // return only fields you need
      // filter results
      filter: {
        id: this.p
      },
      // sort results
      sort: "name_ASC",
      // limit number of results or define range
      limit: "1000",
    };
    // Use get function provided by Angular2PrestaService
    this._service.get(queryProducts).subscribe((product) => {
      this.product = product[0]
      this.product.image = 'https://clandstino.pixelitystudios.com/store/api/images/products/' + this.product.id + '/' + this.product.id_default_image + '?ws_key=' + this.key
      this.product.defaultImage = 'assets/images/default.png'
      this.item.code = this.rest.encode({id: this.item.id})
      console.log(product, this.item)
      setTimeout(() => {
        jQuery('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: true,
          slideshow: true,
          itemWidth: 130,
          itemMargin: 20,
          asNavFor: '#slider',
          smoothHeight: false,
          directionNav: true,
          prevText: "",
          nextText: ""
      });

      jQuery('#slider').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: true,
          slideshow: true,
          sync: "#carousel",
          directionNav: false,
      });
      }, 1000)
    });
  }

  addItem(){
    this.item.quantity++
  }

  removeItem(){
    if(this.item.quantity == 1){

    }else{
      this.item.quantity--
    }
  }

  addToCart(){
    let code = 'IS' + this.item.id + 'SM' + this.item.size + 'QT' + this.item.quantity
    window.open('https://api.whatsapp.com/send?phone=+52+477+708+7235&text=Codigo de cotización: ' + code, '_blank')
  }
}
