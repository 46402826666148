
<div class="breadcrumb-contact-us breadcrumb-section section-box">
    <div class="container breadcrumb-inner">
        <div class="breadcrumbs-wrapper">
            <ul class="phys-breadcrumb">
                <li><a href="/" class="home">Inicio</a></li>
                <li><a href="/#/catalog" class="home">Catálogo</a></li>
                <li>Producto</li>
            </ul>
        </div>
    </div>
</div>

<div class="content-area shop-single-v1-section container" *ngIf="product">
    <div class="product">
        <div class="product-top-image">
            <div class="images">
                <div id="slider" class="flexslider">
                    <div class="flex-viewport">
                        <ul class="slides">
                            <li *ngFor="let image of product.associations.images">
                                <a href="#" class="swipebox" title="">
                                    <img [defaultImage]="product.defaultImage" [lazyLoad]="product.image"
                                            class="attachment-shop_single size-shop_single wp-post-image" alt="#">
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div id="carousel" class="flexslider thumbnail_product">
                    <div class="flex-viewport">
                        <ul class="slides">
                            <li class="flex-active-slide" *ngFor="let image of product.associations.images">
                                <img [defaultImage]="product.defaultImage" [lazyLoad]="product.image"
                                     class="attachment-thumbnail size-thumbnail wp-post-image" alt="#">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="summary entry-summary">
                <h1 class="product_title entry-title">{{product.name[0].value}}</h1>
                <p class="price"><span class="woocommerce-Price-amount amount"><span
                        class="woocommerce-Price-currencySymbol"></span>{{product.price | currency:'MXN':true}}</span></p>
                <div class="woocommerce-product-details__short-description" [innerHTML]="product.description[0].value">
                </div>
                <form class="cart">
                    <div>
                        <div class="btn-group">
                            <button (click)="removeItem()" type="button" class="prev btn ">-</button>
                            <button type="button" class="show-number btn ">{{item.quantity}}</button>
                            <button  (click)="addItem()" type="button" class="next btn ">+</button>
                        </div>
                        <br>
                        <br>
                        <div class="btn-group">
                            <button class="btn" (click)="item.size = 'S'" type="button" [ngClass]="{'active': item.size == 'S'}">S</button>
                            <button class="btn" (click)="item.size = 'M'" type="button" [ngClass]="{'active': item.size == 'M'}">M</button>
                            <button class="btn" (click)="item.size = 'L'" type="button" [ngClass]="{'active': item.size == 'L'}">L</button>
                            <button class="btn" (click)="item.size = 'XL'" type="button" [ngClass]="{'active': item.size == 'XL'}">XL</button>
                        </div>
                        <div class="btn-add-to-cart">
                            <a (click)="addToCart()" class="btn add-to-cart">Añadir al carrito</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
