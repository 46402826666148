import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/product/product.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RestService } from './services/rest.service'
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  Angular2PrestaModule,
  Angular2PrestaConfiguration,
} from "angular2-presta";

const a2pConfig: Angular2PrestaConfiguration = {
  apiKey: "WMICDEFEZ9S9D68W1SJSEG6F6FTHU2NJ",
  shopUrl: "https://www.shop.niabbals.com/api/",
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsComponent,
    ProductComponent,
    CartComponent,
    CheckoutComponent
  ],
  imports: [
    BrowserModule,
    Angular2PrestaModule.forRoot(a2pConfig),
    AppRoutingModule,
    NgxLoadingModule.forRoot({}),
    LazyLoadImageModule,
    NgxPaginationModule
  ],
  providers: [RestService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
