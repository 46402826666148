<div class="breadcrumb-contact-us breadcrumb-section section-box">
    <div class="container breadcrumb-inner">
        <h1 class="heading_primary">Catálogo</h1>
        <div class="breadcrumbs-wrapper">
            <ul class="phys-breadcrumb">
                <li><a href="/" class="home">Inicio</a></li>
                <li>Catálogo</li>
            </ul>
        </div>
    </div>
</div>
<div class="content-area">
    <div class="container">
        <div class="row">
            <div class="site-main col-sm-12 alignleft">

                <div class="wc-sc-product woocommerce featured-layout-1">
                    <div class="inner-sc-product">
                        <ul class="products columns-3">
                            <li class="product" *ngFor="let product of products | paginate: { itemsPerPage: 6, currentPage: p }">
                                <div class="inner-item-product">
                                    <div class="product-image">
                                        <span class="onsale">Sale</span>
                                        <a href="/#/product?id={{product.id}}" class="wp-post-image">
                                            <img [defaultImage]="product.defaultImage" [lazyLoad]="product.image" alt="">
                                        </a>
                                        <div class="box-hover">
                                            <a target="_blank" href="https://api.whatsapp.com/send?phone=+52+477+708+7235&text=Me interesa el producto UID{{product.id}}" data-quantity="1"
                                               class="button product_type_simple add_to_cart_button ajax_add_to_cart"></a>
                                        </div>
                                    </div>
                                    <div class="wrapper-content-item"><h2
                                            class="woocommerce-loop-product_title">
                                        <a href="/#/product?id={{product.id}}"
                                        >{{product.name[0].value}}
                                            v2</a></h2>
                                        <span class="price"><span
                                                class="woocommerce-Price-amount amount"><span
                                                class="woocommerce-Price-currencySymbol"></span>{{product.price | currency:'MXN':true}}</span></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior"
                        nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>