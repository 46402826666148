<!--quick view product-->
<div class="modal fade" id="quickview" tabindex="-1" role="dialog" aria-labelledby="quickview" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
          <div class="product-lightbox">
              <div class="woocommerce">
                  <div class="product shop-single-v1-section">
                      <div class="images images_quick_view">
                          <a href="#" title="Sea sweater">
                              <img src="images/product/Red-Sweater-quickview.jpg"
                                   class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                   alt=""></a>
                      </div>
                      <div class="summary summary_quick_view">
                          <h1 class="product_title entry-title">Sea sweater</h1>
                          <span class="rating">
                              <span class="star-rating">
                                 <i class="fa fa-star "></i> <i class="fa fa-star "></i> <i
                                      class="fa fa-star "></i> <i class="fa fa-star "></i> <i
                                      class="fa fa-star-o "></i>
                              </span>
                              <span class="number-review">( 2 customer reviews )</span>
                          </span>
                          <span class="price">
                              <del><span class="amount"><span>$</span>40</span></del>
                              <ins><span><span>$</span>35</span></ins>
                          </span>
                          <div class="woocommerce-product-details__short-description">
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                          </div>
                          <form class="cart" action="#" method="post"
                                enctype="multipart/form-data">
                              <div>
                                  <div class="btn-group">
                                      <button type="button" class="prev btn ">-</button>
                                      <button type="button" class="show-number btn ">1</button>
                                      <button type="button" class="next btn ">+</button>
                                  </div>
                                  <div class="btn-add-to-cart">
                                      <a href="#" class="btn add-to-cart">ADD TO CART</a>
                                  </div>
                              </div>
                          </form>
                          <div class="yith-wcwl-add-button">
                              <a href="wishlist.html"><i class="fa fa-heart"></i> <span>Add to Wishlist</span></a>
                          </div>
                      </div>
                  </div>
              </div>
              <button title="Close (Esc)" type="button" class="mfp-close" data-dismiss="modal">×</button>
          </div>
      </div>
  </div>
</div>
<!--end quick view product-->
<!--content-->
<div class="wrapper-container">
  <!--mini cart-->
  <div class="minicart-content woocommerce">
      <div class="widget_shopping_cart_contents">
          <p class="close-cart">Close</p>
          <div class="widget_shopping_cart_content">
              <ul class="woocommerce-mini-cart cart_list product_list_widget ">
                  <li class="woocommerce-mini-cart-item mini_cart_item">
                      <a href="#" class="remove remove_from_cart_button"><i class="zmdi zmdi-close"></i>
                      </a>
                      <a href="simple-product.html">
                          <img src="images/product/sneaker5.jpg"
                               class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="">Sneaker v5 </a>
                      <span class="quantity">Qty : 1</span>
                      <span class="price">
                          <span class="woocommerce-Price-amount amount"><span
                                  class="woocommerce-Price-currencySymbol">$</span>70</span>
                      </span>
                  </li>
                  <li class="woocommerce-mini-cart-item mini_cart_item">
                      <a href="#" class="remove remove_from_cart_button"><i class="zmdi zmdi-close"></i>
                      </a>
                      <a href="simple-product.html">
                          <img src="images/product/Hyper-shoe.jpg"
                               class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="">Hyper shoe </a>
                      <span class="quantity">Qty : 1</span> <span class="price"><span
                          class="woocommerce-Price-amount amount"><span
                          class="woocommerce-Price-currencySymbol">$</span>100</span> </span>
                  </li>
              </ul>

              <p class="woocommerce-mini-cart__total total">
                  <strong>Total:</strong> <span class="woocommerce-Price-amount amount"><span
                      class="woocommerce-Price-currencySymbol">$</span>170</span></p>
              <p class="woocommerce-mini-cart__buttons buttons">
                  <a href="cart.html" class="button wc-forward">View cart (2)</a>
                  <a href="checkout.html" class="button checkout wc-forward">Checkout</a>
              </p>
          </div>
      </div>
      <div class="background-overlay-cart"></div>
  </div>
  <!--end mini cart-->
  <!--header-->
  <div class="container container-box-header1">
      <header id="masthead" class="site-header">
          <div class="header-hp-1 style-header-hp-1 affix">
              <div id="js-navbar-fixed" class="menu-desktop">
                  <div class="menu-desktop-inner">
                      <!-- Logo -->
                      <div class="menu-mobile-effect hamburger hamburger--spin">
                          <div class="hamburger-box">
                              <span class="hamburger-inner"></span>
                          </div>
                      </div>
                      <div class="logo">
                          <a href="/" title="azen" rel="home">
                              <img src="https://www.shop.niabbals.com/img/my-shop-logo-1597970180.jpg" alt="azen.physcode.com">
                          </a>
                      </div>
                      <div class="space-header"></div>
                      <!-- Main Menu -->
                      <nav class="main-menu">
                          <ul class="navmenu">
                              <li class="menu-item menu-item-has-children">
                                  <a href="/">Inicio</a>
                              </li>
                              <!--<li class="menu-item menu-item-has-children">
                                  <a href="https://www.shop.niabbals.com/es/2-inicio">Tienda</a>
                              </li>-->
                          </ul>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
  </div>
  <!--end header-->
  <div class="site page-content woocommerce">
    <router-outlet></router-outlet>
  </div>
</div>
<!-- end content -->
<!--footer-->
<div id="wrapper-footer" class="wrapper-footer">
  <div class="container">
      <div class="row">
          <aside class="col-md-4">
              <div class="textwidget"><p><a href="#"><img
                      class="alignnone size-full wp-image-100"
                      src="assets/white.png" alt=""></a></p>
                  <p>Encuentra tu confort con Niabbals</p>
              </div>
          </aside>
          <div class="col-sm-4"></div>
          <aside class="col-md-4 text-footer">
              <div class="textwidget">
                  <ul>
                      <li><i class="zmdi zmdi-home"></i>- L.A.E. Carolina Espinoza Arvizo, Blvd. Clío #1053, Col. Paseos del Country, CP. 37358
                      </li>
                      <li><i class="zmdi zmdi-phone"></i> - 477 740 40 46
                      </li>
                      <li><i class="zmdi zmdi-email"></i> info@niabbals.com
                      </li>
                      <li><i class="zmdi zmdi-time"></i> Lunes - Sábado: 8:00 am - 8:00 pm
                          <p class="footer-sunday">
                              &nbsp;&nbsp;&nbsp;Domingo : Cerrado
                          </p>
                      </li>
                  </ul>
              </div>
          </aside>
      </div>
  </div>
</div>
<!--end footer-->
<span class="footer__arrow-top" style="bottom: 30px;">TOP</span>
