import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Angular2PrestaQuery, Angular2PrestaService } from 'angular2-presta';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products: any = []
  categories: any = []
  key: any;
  p: any = 1


  // Inject Angular2PrestaService
  constructor(private _service: Angular2PrestaService, public rest: RestService) {
    this.key = rest.key
  }

  ngOnInit() {
    let queryProducts: Angular2PrestaQuery = {
      // define resource products, categories ...
      // check full list http://doc.prestashop.com/display/PS16/Web+service+reference
      resource: "products",
      // return only fields you need
      display: "id, id_manufacturer, id_supplier, id_category_default, new, cache_default_attribute, id_default_image, id_default_combination, id_tax_rules_group, position_in_category, manufacturer_name, quantity, type, id_shop_default, reference, supplier_reference, location, width, height, depth, weight, quantity_discount, ean13, isbn, upc, cache_is_pack, cache_has_attachments, is_virtual, state, additional_delivery_times, delivery_in_stock, delivery_out_stock, on_sale, online_only, ecotax, minimal_quantity, low_stock_threshold, low_stock_alert, price, wholesale_price, unity, unit_price_ratio, additional_shipping_cost, customizable, text_fields, uploadable_files, active, redirect_type, id_type_redirected, available_for_order, available_date, show_condition, condition, show_price, indexed, visibility, advanced_stock_management, date_add, date_upd, pack_stock_type, meta_description, meta_keywords, meta_title, link_rewrite, name, description, description_short, available_now, available_later",
      // filter results
      filter: {},
      // sort results
      sort: "name_ASC",
      // limit number of results or define range
      limit: "1000",
    };
    // Use get function provided by Angular2PrestaService
    this._service.get(queryProducts).subscribe((products) => {
      this.products = products.map(x => {
        x.image = 'https://clandstino.pixelitystudios.com/store/api/images/products/' + x.id + '/' + x.id_default_image + '?ws_key=' + this.key
        x.defaultImage = 'assets/images/default.png'
        return x
      })
    });
    let queryCategories: Angular2PrestaQuery = {
      // define resource products, categories ...
      // check full list http://doc.prestashop.com/display/PS16/Web+service+reference
      resource: "categories",
      // return only fields you need
      display: "id, id_parent, level_depth, nb_products_recursive, nleft, nright, active, id_shop_default, is_root_category, position, date_add, date_upd, name, link_rewrite, description, meta_title, meta_description, meta_keywords",
      // filter results
      filter: {},
      // sort results
      sort: "name_ASC",
      // limit number of results or define range
      limit: "1000",
    };
    this._service.get(queryCategories).subscribe((categories) => {
      this.categories = categories.filter(x => x.id !== 1 && x.id !== 2)
    });
  }
}
