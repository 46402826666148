<div class="home-content" role="main">
    <!--REVOLUTION SLIDER-->
    <div id="rev_slider_1_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container slider-homepage "
         data-alias="slider-homepage-1" data-source="gallery">
        <!-- START REVOLUTION SLIDER 5.4.8.3 fullwidth mode -->
        <div id="rev_slider_1_1" class="rev_slider fullwidthabanner" style="display:none;"
             data-version="5.4.8.3">
            <ul>    <!-- SLIDE  -->
                <li data-index="rs-1"
                    data-transition="slideleft,slideremoveleft,slotfade-horizontal,slotslide-vertical,fadetorightfadefromleft,fadefromtop,fadefromright,parallaxtotop,parallaxtobottom,curtain-3,curtain-1,cube-horizontal,incube-horizontal,turnoff-vertical,flyin,random-static"
                    data-slotamount="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-randomtransition="on"
                    data-easein="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-easeout="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-masterspeed="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-thumb="assets/slide1-hp1-100x50.jpg" data-rotate="0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
                    data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3=""
                    data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9=""
                    data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/slider/1.png" alt="" data-bgposition="center center"
                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg"
                         data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 1 -->
                    <div class="tp-caption   tp-resizeme  title"
                         id="slide-1-layer-1"
                         data-x="['left','left','left','left']" data-hoffset="['203','127','102','50']"
                         data-y="['top','top','top','top']" data-voffset="['279','230','174','129']"
                         data-letterspacing="['2','3','3','3']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 5; white-space: nowrap; font-size: 24px; line-height: 24px; font-weight: 600; color: #ffffff; letter-spacing: 2px;font-family:Muli;">
                        Tu confort al caminar
                    </div>

                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption   tp-resizeme  title-heavy"
                         id="slide-1-layer-2"
                         data-x="['left','left','left','left']" data-hoffset="['195','123','98','49']"
                         data-y="['top','top','top','top']" data-voffset="['324','277','210','168']"
                         data-fontsize="['76','60','40','30']"
                         data-lineheight="['85','70','50','40']"
                         data-fontweight="['800','600','600','600']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:left;","ease":"nothing"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 6; white-space: nowrap; font-size: 76px; line-height: 85px; font-weight: 800; color: #ffffff; letter-spacing: 0px;font-family:Muli;text-transform:uppercase;">
                        NO TIENE PRECIO
                    </div>
                </li>
                <!-- SLIDE  -->
                <li data-index="rs-2"
                    data-transition="slideleft,slideremoveleft,slotfade-horizontal,slotslide-vertical,fadetorightfadefromleft,fadefromtop,fadefromright,parallaxtotop,parallaxtobottom,curtain-3,curtain-1,cube-horizontal,incube-horizontal,turnoff-vertical,flyin,random-static"
                    data-slotamount="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-randomtransition="on"
                    data-easein="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-easeout="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-masterspeed="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-thumb="assets/slide2-hp1-100x50.jpg" data-rotate="0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
                    data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3=""
                    data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9=""
                    data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/slider/2.png" alt="" data-bgposition="center center"
                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg"
                         data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 4 -->
                    <div class="tp-caption   tp-resizeme  title"
                         id="slide-2-layer-1"
                         data-x="['left','left','left','left']" data-hoffset="['175','127','103','53']"
                         data-y="['top','top','top','top']" data-voffset="['285','221','170','136']"
                         data-letterspacing="['2','3','3','3']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"y:bottom;rZ:90deg;sX:2;sY:2;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"nothing"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 5; white-space: nowrap; font-size: 24px; line-height: 24px; font-weight: 600; color: #ffffff; letter-spacing: 2px;font-family:Muli;">
                        Nunca sabes lo que es confort
                    </div>

                    <!-- LAYER NR. 5 -->
                    <div class="tp-caption   tp-resizeme  title-heavy"
                         id="slide-2-layer-2"
                         data-x="['left','left','left','left']" data-hoffset="['171','122','101','51']"
                         data-y="['top','top','top','top']" data-voffset="['324','263','206','176']"
                         data-fontsize="['76','60','40','35']"
                         data-lineheight="['76','50','50','30']"
                         data-fontweight="['800','600','600','600']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:bottom;","ease":"Power2.easeIn"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 6; white-space: nowrap; font-size: 76px; line-height: 76px; font-weight: 800; color: #ffffff; letter-spacing: 0px;font-family:Muli;text-transform:uppercase;">
                        hasta que  <br> compras en  <br>   NIABBALS <br>
                    </div>
                </li>
                <!-- SLIDE  -->
                <li data-index="rs-3"
                    data-transition="slideleft,slideremoveleft,slotfade-horizontal,slotslide-vertical,fadetorightfadefromleft,fadefromtop,fadefromright,parallaxtotop,parallaxtobottom,curtain-3,curtain-1,cube-horizontal,incube-horizontal,turnoff-vertical,flyin,random-static"
                    data-slotamount="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-randomtransition="on"
                    data-easein="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-easeout="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-masterspeed="default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default"
                    data-thumb="assets/slide3-hp1-100x50.jpg" data-rotate="0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
                    data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3=""
                    data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9=""
                    data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/slider/3.png" alt="" data-bgposition="center center"
                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg"
                         data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption   tp-resizeme  title"
                         id="slide-3-layer-1"
                         data-x="['left','left','left','left']" data-hoffset="['175','128','104','54']"
                         data-y="['top','top','top','top']" data-voffset="['293','193','167','122']"
                         data-letterspacing="['2','3','3','3']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:right;","ease":"Power3.easeInOut"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 5; white-space: nowrap; font-size: 24px; line-height: 24px; font-weight: 600; color: #ffffff; letter-spacing: 2px;font-family:Muli;">
                        La culpa de gastar se me acaba
                    </div>

                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption   tp-resizeme  title-heavy"
                         id="slide-3-layer-2"
                         data-x="['left','left','left','left']" data-hoffset="['169','124','99','51']"
                         data-y="['top','top','top','top']" data-voffset="['334','239','210','160']"
                         data-fontsize="['76','60','40','40']"
                         data-lineheight="['76','50','50','30']"
                         data-fontweight="['800','600','600','600']"
                         data-width="none"
                         data-height="none"
                         data-whitespace="nowrap"

                         data-type="text"
                         data-responsive_offset="on"

                         data-frames='[{"delay":0,"speed":1000,"sfxcolor":"rgba(255,255,255,0.28)","sfx_effect":"blockfrombottom","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:-50px;opacity:0;","ease":"nothing"}]'
                         data-textAlign="['inherit','inherit','inherit','inherit']"
                         data-paddingtop="[0,0,0,0]"
                         data-paddingright="[0,0,0,0]"
                         data-paddingbottom="[0,0,0,0]"
                         data-paddingleft="[0,0,0,0]"

                         style="z-index: 6; white-space: nowrap; font-size: 76px; line-height: 76px; font-weight: 800; color: #ffffff; letter-spacing: 0px;font-family:Muli;text-transform:uppercase;">
                        al caminar con <br> plantillas  <br> comodas
                    </div>

                </li>
            </ul>
            <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
        </div>
    </div>

    <div class="intro-hp4">
        <div class="container">
            <div class="row">
                
                <div class="col-lg-4 col-md-4 col-12">
                    <div class="content-intro-hp4">
                        <h2 class="heading-title">Misión</h2>
                        <p>Ser el  # 1 en productos novedosos e inteligentes, generando una alternativa de salud y bienestar avalados clínicamente. Lograr ser una marca lº en el mercado con la diversificación de productos para el consumidor final, teniendo alianzas comerciales de distribución nacional e internacional.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                    <div class="content-intro-hp4">
                        <h2 class="heading-title">Visión</h2>
                        <p>Desarrollar e innovar productos inteligentes para cumplir las exigencias y necesidades de nuestros clientes nacionales y extranjeros, para brindar confort, comodidad, salud y bienestar Mediante la fabricación de plantillas y productos de restauración para cualquier tipo de calzado; así como productos ortopédicos para la prevención y corrección en problemas en los pies.</p>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-12">
                    <div class="content-intro-hp4">
                        <h2 class="heading-title">Valores</h2>
                        <div>
                            <span>- Actitud de servicio</span>
                            <span>&nbsp; - Competitividad</span>
                            <span>&nbsp; - Caspandad</span>
                            <span>&nbsp; - Efectividad</span>
                            <span>&nbsp; - Rentabispandad</span>
                            <span>&nbsp; - Integridad</span>
                            <span>&nbsp; - Responsabispandad</span>
                            <span>&nbsp; - Confianza</span>
                            <span>&nbsp; - Compromiso</span>
                            <span>&nbsp; - Honestidad</span>
                            <span>&nbsp; - Caspandad humana</span>
                            <span>&nbsp; - Profesionaspansmo</span>
                            <span>&nbsp; - Trabajo en equipo</span>
                            <span>&nbsp; - Creatividad</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END REVOLUTION SLIDER -->
    <div class="container heading-product">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <h2 class="heading-title">Productos favoritos</h2>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                <p>Conóce la gran variedad de plantillas para tu comidad!
                </p>
            </div>
        </div>
    </div>
    <!--product-->
    <div class="container">
        <div class="wc-sc-product woocommerce featured-layout-1">
            <div class="inner-sc-product">
                <ul class="products columns-4">
                    <li class="product" *ngFor="let product of products | paginate: { itemsPerPage: 6, currentPage: p }">
                        <div class="inner-item-product">
                            <div class="product-image">
                                <span class="onsale">Sale</span>
                                <a href="https://www.shop.niabbals.com/es/2-inicio" class="wp-post-image">
                                    <img [defaultImage]="product.defaultImage" [lazyLoad]="product.image" style="min-height: 200px" alt="">
                                </a>
                                <div class="box-hover">
                                    <a target="_blank" href="https://api.whatsapp.com/send?phone=+52+477+708+7235&text=Me interesa el producto UID{{product.id}}" data-quantity="1"
                                       class="button product_type_simple add_to_cart_button ajax_add_to_cart"></a>
                                </div>
                            </div>
                            <div class="wrapper-content-item"><h2
                                    class="woocommerce-loop-product_title">
                                <a href="https://www.shop.niabbals.com/es/2-inicio"
                                >{{product.names}}</a></h2>
                                <span class="price"><span
                                        class="woocommerce-Price-amount amount"><span
                                        class="woocommerce-Price-currencySymbol"></span>{{product.price | currency:'MXN':true}}</span></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!--end product-->
    <!--banner-->
    <div class="intro-hp4">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 offset-lg-1 col-md-6 col-12">
                    <div class="elementor-image">
                        <img src="assets/images/banner/banner-hp1.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="content-intro-hp4">
                        <h2 class="heading-title">PLANTILLA ANATOMICA DE PIEL CON BURBUJA DE GEL</h2>
                        <p>Gracias a su diseño anatómico brinda alto confort al caminar  y soporte extra en las partes donde se ejerce mayor impacto, principalmente en el talón.</p>
                        <p>Da ajuste al calzado, reduce hasta ½ talla. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end banner-->
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>