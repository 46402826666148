import { Injectable } from '@angular/core';
declare let CryptoJS: any
@Injectable({
  providedIn: 'root'
})
export class RestService {
  key: string = 'WMICDEFEZ9S9D68W1SJSEG6F6FTHU2NJ'
  constructor() { }

  base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);
  
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');
  
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
  
    return encodedSource;
  }

  encode(data){
    var header = {
      "alg": "HS256",
      "typ": "JWT"
    };
    
    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = this.base64url(stringifiedHeader);
    
    
    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    var encodedData = this.base64url(stringifiedData);
    
    var token = encodedHeader + "." + encodedData;

    var secret = 'cland.stino';

    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = this.base64url(signature);

    return  token + "." + signature;
  }
}
